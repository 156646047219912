import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
export default createStore({
  state: {
    user:{
      userId:0,
      nickName:'',
      birthday:null,
      gender:'',
      phoneNumber:'',
      email:'',

    },
    iconUrl:''
  },
  getters: {
  },
  mutations: {
    userChange(state,userInfo){
      state.user.userId = userInfo['userId']
      state.user.nickName = userInfo['nickName']
      state.user.birthday = userInfo['birthday']
      state.user.gender = userInfo['gender']
      state.user.phoneNumber = userInfo['phoneNumber']
      state.user.email = userInfo['email']
    },
    iconUrlChange(state,iconUrl){
      state.iconUrl = iconUrl
    }
  },
  actions: {

  },
  modules: {

  },
  /* vuex数据持久化配置 */
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies
      storage: window.localStorage,
      // 存储的 key 的key值
      key: "store",
      reducer(state) { //render错误修改
        // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
        return { ...state };
      }
    })
  ]
})
