<template>

  <router-view></router-view>
</template>

<script >

import {isOnline} from "@/api/UserApi";
import store from "@/store";

export default {
  name: 'app',
  components:{

  },
  created(){
    if (store.state.user.userId !== 0){
      isOnline().catch(res=>{
        if (res.code !== 0){
          localStorage.clear()
        }
      }).catch(e=>{
        console.log(e)
      })
    }
  }


}
</script>

