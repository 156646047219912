import request from "@/utils/request";
import store from "@/store";

export function userLoginApi(loginUser){
    let userId = loginUser.userId
    let passWord = loginUser.passWord
    let code = loginUser.code
    let codeKey = loginUser.codeKey
    let loginType = loginUser.loginType
    return request({
        url: '/user/userLogin',
        method: 'post',
        data:{
            "userId":userId,
            "passWord":passWord,
            "loginType":loginType,
            "codeKey":codeKey,
            "code":code
        }
    })
}

export function userRegister(registerUser){
    return request({
        url: '/user/userRegister',
        method: 'post',
        data:{
            "nickName":registerUser.nickName,
            "passWord":registerUser.passWord,
            "birthday":registerUser.birthday,
            "gender":registerUser.gender,
            "phoneNumber":registerUser.phoneNumber,
            "email":registerUser.email,
            "code":registerUser.code
        }
    })
}

export function getEatMenu(){
    const userId = store.state.user.userId
    return request({
        url: '/user/getUserMenu',
        method: 'post',
        data:{
            'userId':userId
        }
    })
}

export function saveMenuApi(allChoose){
    const userId = store.state.user.userId
    if(allChoose === undefined){
        allChoose = []
    }
    return request({
        url: '/user/saveMenuApi',
        method: 'post',
        data:{
            'userId':userId,
            'menu':allChoose
        }
    })
}
export function userLogout(){
    return request({
        url: '/user/userLogout',
        method: 'get',
    })
}
export function userIconUpload(file,fileType) {
    const formDate = new FormData()
    formDate.append('file', file.file)
    formDate.append('fileType', fileType)
    return request({
        url:'/user/uploadIcon/'+store.state.user.userId,
        method: 'post',
        data:formDate,
        headers: {
            'Content-type': 'multipart/form-data',
        }
    })
}

export function changPasswordByPassword(passwordList) {
    return request({
        url:'/user/changPasswordByPassword',
        method: 'post',
        data:{
            'oldPassword': passwordList.oldPassword,
            'newPassword': passwordList.newPassword
        }
    })
}
export function changePasswordByEmail(user) {
    return request({
        url:'/user/changePasswordByEmail',
        method: 'post',
        data:{
            'userId': user.userId,
            'code':user.code,
            'passWord': user.password
        }
    })
}
export function isOnline() {
    return request({
        url:'/user/isOnline',
        method: 'post',
        data:{
        }
    })
}
export function getUserFileList() {
    return request({
        url:'/user/getUserFileList',
        method: 'get'
    })
}

export function deleteUserFile(fuuid,fileName){
    return request({
        url:'/user/deleteUserFile',
        method: 'post',
        data:{
            'userId': store.state.user.userId,
            'fuuid': fuuid,
            'fileName': fileName
        }
    })
}
/*
* 获取用户消费
 */
export function getUserCostApi(startDate,endDate){
    return request({
        url:'/user/getUserCost',
        method: 'post',
        data:{
            'userId': store.state.user.userId,
            'startDate': startDate,
            'endDate': endDate
        }
    })
}

/*
* 更新用户消费
 */
export function updateUserCostApi(costList){
    return request({
        url:'/user/updateUserCost',
        method: 'post',
        data:{
            'costList': costList
        }
    })
}
/*
 ** 获取用户列表
 */
export function getUserListApi(){
    return request({
        url:'/user/getUserList',
        method: 'get'
    })
}
/*
 ** 获取用户消费汇总
 */
export function getUserCostSummaryApi(userList,startDate,endDate){
    return request({
        url:'/user/getUserCostSummary',
        method: 'post',
        data:{
            'userList': userList,
            'startDate': startDate,
            'endDate': endDate
        }
    })
}
